@tailwind base;
@tailwind components;
@tailwind utilities;

@media (min-width: 768px) {
	html {
		@apply overflow-y-scroll;
	}
}

.w-eighth {
	width: 12.5%;
}

.button {
	@apply bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 lg:py-2 lg:px-4 rounded;
}

.button-danger {
	@apply bg-red-400 hover:bg-red-500 text-white font-bold py-1 px-2 lg:py-2 lg:px-4 rounded;
}

.button:disabled {
	@apply bg-red-300;
}

button.smallLink {
	@apply text-blue-600 text-xs;
}

button:focus {
	outline: none;
}

a {
	@apply text-green-600;
}

a:hover {
	@apply text-green-300;
}

.form-field {
	@apply py-3;
}

.form-field:nth-of-type(odd) {
	background-color: rgba(16, 185, 129, 0.05);
}

.form-field:nth-of-type(even) {
	background-color: rgba(16, 185, 129, 0.01);
}

.search-results-item:nth-of-type(odd) {
	background-color: rgba(16, 185, 129, 0.05);
}

.search-results-item:nth-of-type(even) {
	background-color: rgba(16, 185, 129, 0.01);
}

.blossom-harvest-row:nth-of-type(odd) {
	background-color: rgba(16, 185, 129, 0.05);
}

.blossom-harvest-row:nth-of-type(even) {
	background-color: rgba(16, 185, 129, 0.01);
}

.growing-info-table tr:nth-of-type(odd) {
	background-color: rgba(16, 185, 129, 0.05);
}

.growing-info-table tr:nth-of-type(even) {
	background-color: rgba(16, 185, 129, 0.01);
}

.growing-info-table tbody td {
	@apply text-sm lg:text-lg;
}

.cider-info-table tbody td {
	@apply text-sm lg:text-lg;
}

.cider-info-table tr:nth-of-type(odd) {
	background-color: rgba(16, 185, 129, 0.05);
}

.cider-info-table tr:nth-of-type(even) {
	background-color: rgba(16, 185, 129, 0.01);
}

.sugar-levels-table tr:nth-of-type(odd) {
	background-color: rgba(16, 185, 129, 0.05);
}

.sugar-levels-table tr:nth-of-type(even) {
	background-color: rgba(16, 185, 129, 0.01);
}

.prose tbody td {
	vertical-align: middle !important;
}

.prose tbody td:first-child {
	text-align: center;
	/* font-weight: bold; */
}

.prose tbody td:nth-child(2) {
	@apply text-center;
}

.prose table {
	max-width: 100% !important;
	margin-bottom: 0.5rem !important;
}

@layer utilities {
	@variants responsive {
		.text-shadow {
			text-shadow: 1px 1px 3px black;
		}
		.text-shadow-md {
			text-shadow: 3px 3px 6px black;
		}
		.text-shadow-lg {
			text-shadow: 5px 5px 20px black;
		}
		.text-shadow-none {
			text-shadow: none;
		}
	}
}
